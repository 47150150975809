import React, { useEffect } from "react";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import { calculateReadingTime } from "../../utils/blog";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Import Font Awesome component
import { faEdit } from "@fortawesome/free-solid-svg-icons"; // Import edit icon

import {
  changeLimit,
  changeMode,
  changePage,
  changeSelectedBlog,
  getBlog,
  resetMode,
  resetSelectedBlog,
} from "../../reducers/blogSlice";
import { getDate } from "../../utils/datemaker";
import Loader from "../../components/Loader";

const Blog = () => {
  const { isLogedIn, user } = useSelector((state) => state.authenticate);
  const { blogData, totalBlogCount, page, limit, loading } = useSelector(
    (state) => state.blogSlice
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(getBlog({ page: page, limit: 100000 ,user}));
  }, [page, limit,user]);

  // useEffect(() => {
  //   console.log(blogData);
  // }, [blogData]);

  const handleAddNewBlog = () => {
    navigate("/admin/add-blog");
  };

  const handleEditBlog = (post) => {
    dispatch(changeSelectedBlog(post));
    dispatch(changeMode("update"));
    navigate("/admin/add-blog");
  };

  const modalLoad = () => {
    return <Loader visible={loading} />;
  };

  return (
    <div className="bg-gray-100 min-h-screen lg:min-h-[150vh] py-8 pt-20">
      {loading && modalLoad()}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-row justify-between align-middle my-8 bg-gray-200 rounded-lg p-2">
          <div className="flex flex-row align-middle">
            <span className="text-2xl sm:text-2xl font-bold text-center text-gray-800">
              Our Latest Blogs
            </span>
          </div>

          {/* Add Blog Button */}
          {isLogedIn && user?.role === "ADMIN" && (
            <div className="">
              <button
                onClick={handleAddNewBlog}
                className="text-white bg-gray-600 hover:bg-gray-700 px-4 py-2 rounded-md"
              >
                + Add New Blog
              </button>
            </div>
          )}
        </div>

        {/* Scrollable Blog Container */}
        {blogData?.length>0 ? (
          <div className="overflow-y-auto max-h-[calc(150vh-300px)] scrollbar-thin">
            <div className="grid gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
              {blogData.map((post, index) => (
                <div
                  key={index}
                  className="bg-white shadow-lg rounded-lg overflow-hidden flex flex-col"
                >
                  {/* Blog Image */}
                  <img
                    src={post?.images? post?.images[0]?.url:""}
                    alt={post?.title}
                    className="w-full h-48 object-cover"
                  />

                  {/* Blog Content */}
                  <div className="p-6 flex flex-col flex-grow">
                    <h2 className="text-xl sm:text-2xl font-semibold mb-2 text-gray-800">
                      {post.title}
                    </h2>
                    <div className="flex flex-row justify-between mb-4">
                      <p className="text-sm sm:text-base text-gray-500 mb-2">
                        {getDate(post.updatedAt)}
                      </p>
                      <p className="text-sm sm:text-base text-gray-500 mb-2">
                        {calculateReadingTime(post?.content)} min read
                      </p>
                    </div>
                    <p className="text-gray-600 mb-4">
                      {post?.description}
                    </p>

                    {/* Align "Read More" link at the bottom-right */}
                    <div className="mt-auto w-full flex justify-between">
                      <Link
                        to={`/blog/${post._id}`}
                        className="text-blue-700 underline"
                      >
                        Read More
                      </Link>

                      {/* Edit button for admins */}
                      {isLogedIn && user?.role === "ADMIN" && (
                        <button
                          onClick={() => handleEditBlog(post)}
                          className="text-gray-500 hover:text-gray-700 ml-4"
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="mt-5 text-lg text-">No Blog Found !!!</div>
        )}
      </div>
    </div>
  );
};

export default Blog;
