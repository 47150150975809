import { Outlet } from "react-router-dom";
import Header from "../../components/Header";
import { useLocation } from "react-router-dom";
import Landing from "../Landing";
import Footer from "../../components/Footer";
import "./style.css";

const Home = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <div>
      <header className="header__app">
        <Header />
      </header>
      <div className={``}>{currentPath == "/" ? <Landing /> : <Outlet />}</div>
     <footer>
       <Footer />
     </footer>
    </div>
  );
};

export default Home;
