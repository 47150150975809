import axios from "axios";
import axiosInstance from "./axios.instance";


const API_LOGIN = async (userCredentials) => {
    try {
       // console.log(userCredentials)
        const resp = await axiosInstance.post('/auth/login', userCredentials);
        //console.log(resp)
        return resp;
    } catch (err) {
        console.log(err)
        throw err
    }
}


const API_GET_ALL_USER = async () => {
    try {
        
        const resp = await axiosInstance.get('auth/user/all');
        //console.log(resp)
        return resp;
    } catch (err) {
        console.log(err)
        throw err
    }
}

const API_LOGOUT = async () => {
    try {
        const resp = await axiosInstance.get('/auth/logout');
        return resp.data;
    } catch (err) {
        throw err
    }
}


export { API_LOGIN, API_LOGOUT,API_GET_ALL_USER };
