import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { calculateReadingTime } from "../../../utils/blog";
import { getBlog } from "../../../reducers/blogSlice";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getDate } from "../../../utils/datemaker";

const Suggestion = () => {
  const { _id } = useParams();
  const dispatch = useDispatch();
  // State for comments
  const [blogs, setBlogs] = useState([]);
  const { blogData } = useSelector(
    (state) => state.blogSlice
  );
  useEffect(() => {
    dispatch(getBlog({ page: 0, limit: 100000 }));
  }, []);

  useEffect(() => {
    console.log(blogData);
    const blog = blogData.filter((blog)=>blog._id !== _id);
    setBlogs(blog)
  }, [blogData]);

  const suggestions = [
    {
      title: "Exploring Virtual Reality in Education",
      date: "October 3, 2024",
      imageUrl: "https://picsum.photos/100/100?random=1",
    },
    {
      title: "How to Build an Engaging VR Physics Lab",
      date: "September 28, 2024",
      imageUrl: "https://picsum.photos/100/100?random=2",
    },
    {
      title: "Resonance Columns in VR: A New Way to Learn",
      date: "September 15, 2024",
      imageUrl: "https://picsum.photos/100/100?random=3",
    },
    {
      title: "The Future of VR in Education",
      date: "August 10, 2024",
      imageUrl: "https://picsum.photos/100/100?random=4",
    },
    {
      title: "VR vs AR: A Comparison",
      date: "July 25, 2024",
      imageUrl: "https://picsum.photos/100/100?random=5",
    },
    {
      title: "Resonance Columns in VR: A New Way to Learn",
      date: "September 15, 2024",
      imageUrl: "https://picsum.photos/100/100?random=13",
    },
    {
      title: "The Future of VR in Education",
      date: "August 10, 2024",
      imageUrl: "https://picsum.photos/100/100?random=14",
    },
    {
      title: "VR vs AR: A Comparison",
      date: "July 25, 2024",
      imageUrl: "https://picsum.photos/100/100?random=15",
    },
    // Add more blogs here as needed
  ];

  return (
    <div className="bg-white p-4 shadow-lg rounded-lg max-h-[calc(100vh-10px)] sm:max-h-[calc(100vh-50px)]">
      <h2 className="text-lg font-semibold mb-4">You Might Also Like</h2>

      {/* Scrollable container */}
      <div className="overflow-y-scroll scrollbar-thin max-h-[calc(100vh-200px)] sm:max-h-[calc(100vh-150px)]">
        <div className="flex flex-col space-y-4">
          {blogs.map((suggestion, index) => (
            <div
              key={index}
              className="flex items-start space-x-4 border-b pb-4 last:border-b-0"
            >
              {/* Image */}
              <img
                src={suggestion?.images? suggestion?.images[0]?.url:""}
                alt={suggestion.title}
                className="w-16 h-16 rounded object-cover"
              />

              {/* Blog Details */}
              <div className="flex flex-col ">
                <Link
                  to={`/blog/${index + 1}`}
                  className="text-blue-700 font-semibold text-sm sm:text-base mr-2"
                >
                  {suggestion.title}
                </Link>
                <p className="text-gray-600 text-sm mb-1">{suggestion?.description}</p>
                <span className="text-xs text-gray-500"> {getDate(suggestion.updatedAt)}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Suggestion;
