import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeHighlight from "rehype-highlight";
import { calculateReadingTime } from "../../../utils/blog";
import { useForm } from "react-hook-form"; // Import React Hook Form
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Import Font Awesome component
import { faEdit } from "@fortawesome/free-solid-svg-icons"; // Import edit icon
import { Link, useNavigate } from "react-router-dom";
import {
  changeLimit,
  changeMode,
  changePage,
  changeSelectedBlog,
  getBlogById,
  getBlog,
  resetMode,
  resetSelectedBlog,
} from "../../../reducers/blogSlice";
import { useSelector, useDispatch } from "react-redux";
import { getDate } from "../../../utils/datemaker";
import { Helmet } from "react-helmet-async";

const Detail = () => {
  const { _id } = useParams();
  const { isLogedIn, user } = useSelector((state) => state.authenticate);
  const { blog, loading } = useSelector((state) => state.blogSlice);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // State for comments
  const [comments, setComments] = useState(blog ? blog?.comments : []);

  useEffect(() => {
    dispatch(getBlogById(_id));
    dispatch(getBlog({ page: 0, limit: 100000 }));
  }, [_id]);

  useEffect(() => {
    console.log(blog);
  }, [blog]);

  // Initialize React Hook Form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // Handle adding a new comment
  const onSubmit = (data) => {
    const newCommentData = {
      id: comments.length + 1,
      author: "Anonymous", // You can replace this with actual user info if available
      text: data.comment,
    };

    setComments([...comments, newCommentData]);
    reset(); // Clear the form after submission
  };

  const handleEditBlog = (post) => {
    dispatch(changeSelectedBlog(post));
    dispatch(changeMode("update"));
    navigate("/admin/add-blog");
  };

  if (!blog) {
    return (
      <div className="max-w-4xl mx-auto text-4xl">Blog post not found!</div>
    );
  }

  return (
    <div className="w-full max-w-4xl p-0 lg:p-2 mx-auto ">
      <Helmet>
        <title>{blog?.meta?.title}</title>
        <meta
          name="description"
          content={blog?.meta?.description}
          />
        <meta
          name="keywords"
          content={blog?.meta?.keywords?.join(", ")}
        />
        <meta name="author" content={blog?.author?.userName}/>
        <meta name="robots" content={blog?.meta?.robots}/>
        <link rel="canonical" href="https://astomverse.com/blog" />
      </Helmet>
      <div className="bg-white shadow-md rounded-lg overflow-hidden mb-8">
        {/* Blog Image */}
        <img
          src={blog?.images ? blog?.images[0]?.url : ""}
          alt={blog?.title}
          className="w-full h-72 sm:h-96 object-cover mb-6"
        />

        {/* Blog Content */}
        <div className="p-6 sm:p-10">
          <div className="flex flex-col sm:flex-row justify-between text-sm sm:text-base text-gray-500 mb-6">
            <p className="text-md text-gray-500 "> {getDate(blog.updatedAt)}</p>
            <div className="flex flex-row align-middle justify-center gap-2">
              <div>
                <p className="text-md text-gray-500 ">
                  {blog ? calculateReadingTime(blog?.content) : ""} min read
                </p>
              </div>
              {isLogedIn && user?.role === "ADMIN" && (
                <button
                  onClick={() => handleEditBlog(blog)}
                  className="text-gray-500 hover:text-gray-700 ml-4"
                >
                  <FontAwesomeIcon icon={faEdit} />
                </button>
              )}
            </div>
          </div>
          <div className="prose lg:prose-xl">
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeHighlight]}
            >
              {blog?.content}
            </ReactMarkdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Detail;
