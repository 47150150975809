import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  API_GET_BLOG,
  API_ADD_BLOG,
  API_DELETE_BLOG,
  API_UPDATE_BLOG,
  API_GET_BLOGBYID,
} from "../api/blog.service";
import { updateTableData, removeDeleteData } from "../utils/tableUtils";
import axios from "axios";
import { API_GET_URL } from "../api/upload.service";
import { API_DELETE_ASSETS, API_UPDATE_ASSETS } from "../api/assets.service";

const initialState = {
  loading: false,
  blogData: [],
  blog: null,
  totalBlogCount: 0,
  selectedBlog: null,
  page: 0,
  limit: 5,
  mode: null,
};

export const getBlog = createAsyncThunk(
  "blogTable/getBlogList",
  async ({ page, limit,user }, thunkAPI) => {
   // console.log(page, limit);
    try {
      const blogData = await API_GET_BLOG(page, limit,user);
      return blogData;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getBlogById = createAsyncThunk(
  "blogTable/getBlogById",
  async (id, thunkAPI) => {
    try {
      const blogData = await API_GET_BLOGBYID(id);
      return blogData;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const addBlog = createAsyncThunk(
  "blogTable/addBlogList",
  async ({ data, selectedImage }, thunkAPI) => {
    try {
      //console.log(data);
      const blog = await API_ADD_BLOG(data);
      if (blog && selectedImage) {
        const key = blog.data?._id;
        const imgUrl = await API_GET_URL({
          referenceId: key,
          name: blog.data?.title,
          assetType: "blog",
        });
        await axios.put(imgUrl.data?.src, selectedImage, {
          headers: { "Content-Type": "image/png" },
        });
      }
      return blog;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const updateBlog = createAsyncThunk(
  "blogTable/updateBlogList",
  async ({ selectedBlog, data, selectedImage }, thunkAPI) => {
    //console.log(selectedBlog);
    try {
      if (selectedImage) {
        const assetsObj = selectedBlog?.images?.find(
          (asset) => asset.assetType == "blog"
        );
        //console.log(assetsObj);
        if (assetsObj) {
          const imgUrl = await API_UPDATE_ASSETS(assetsObj?._id);
          // console.log(del);
          await axios.put(imgUrl.data?.src, selectedImage, {
            headers: { "Content-Type": "image/png" },
          });
        }
      }

      const blog = await API_UPDATE_BLOG(selectedBlog._id, data);
      return blog;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const deleteBlog = createAsyncThunk(
  "blogTable/deleteBlog",
  async (data, thunkAPI) => {
    //console.log(data);
    try {
      const Blog = await API_DELETE_BLOG(data);
      return Blog;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

const blogSlice = createSlice({
  name: "blogTable",
  initialState,
  reducers: {
    changeMode(state, action) {
      state.mode = action.payload;
    },
    resetMode(state) {
      state.mode = null;
    },
    changeSelectedBlog(state, action) {
      state.selectedBlog = action.payload;
    },
    resetSelectedBlog(state) {
      state.selectedBlog = null;
    },
    changePage(state, action) {
      state.page = action.payload;
    },
    changeLimit(state, action) {
      state.limit = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getBlog.fulfilled, (state, action) => {
      state.totalBlogCount = action.payload.count;
      state.blogData = action.payload.rows.reverse();
      state.loading = false;
    });
    builder.addCase(getBlog.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBlog.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getBlogById.fulfilled, (state, action) => {
      console.log(action.payload);
      state.blog = action.payload.data;
      state.loading = false;
    });
    builder.addCase(getBlogById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBlogById.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(addBlog.fulfilled, (state, action) => {
      let data = action.payload.data;
      console.log(data);
      if (state.blogData.length < state.limit) {
        state.blogData = [...state.blogData, data];
      } else {
        state.blogData = [...state.blogData.slice(0, state.limit - 1), data];
      }
      state.totalBlogCount += 1;
      state.loading = false;
    });
    builder.addCase(addBlog.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addBlog.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateBlog.fulfilled, (state, action) => {
      console.log(action.payload.data);
      state.blogData = updateTableData(state.blogData, action.payload.data);
      state.loading = false;
    });
    builder.addCase(updateBlog.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateBlog.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(deleteBlog.fulfilled, (state, action) => {
      console.log(action.payload);
      state.totalBlogCount -= 1;
      state.blogData = removeDeleteData(state.blogData, action.payload.id);
      state.loading = false;
      state.mode = null;
    });
    builder.addCase(deleteBlog.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteBlog.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  changeMode,
  resetMode,
  changeSelectedBlog,
  resetSelectedBlog,
  changePage,
  changeLimit,
} = blogSlice.actions;

export default blogSlice.reducer;
