import axiosInstance from "./axios.instance";
import axios from "axios";

const API_GET_ASSETS = async (pageNo, limit, filter) => {
  try {
    if (filter) {
      const resp = await axiosInstance.get(
        `/asset/all?page=${pageNo}&limit=${limit}&assetType=model&status=${filter}`
      );
      return resp.data;
    }
    const resp = await axiosInstance.get(
      `/asset/all?page=${pageNo}&limit=${limit}&assetType=model`
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
};

const API_GET_ASSETSBYID = async (id, data) => {
  console.log(id, data);
  try {
    const resp = await axiosInstance.get(`/asset/${id}`, data);
    return resp;
  } catch (err) {
    throw err;
  }
};

const API_ADD_ASSETS = async (data) => {
  console.log(data);
  try {
    const resp = await axiosInstance.post(`/asset`, data);
    console.log(resp);
    return resp;
  } catch (err) {
    throw err;
  }
};

const API_UPDATE_ASSETS = async (id, data) => {
  console.log(id, data);
  try {
    const resp = await axiosInstance.put(`/asset/${id}/resource`, data);
    return resp;
  } catch (err) {
    throw err;
  }
};

const API_UPDATE_ASSETS_STATUS = async (id, data) => {
  console.log(id, data);
  try {
    const resp = await axiosInstance.put(`/asset/${id}`, data);
    return resp;
  } catch (err) {
    throw err;
  }
};

const API_UPDATE_ASSETS_COMMENTS = async (id, data) => {
  console.log(id, data);
  try {
    const resp = await axiosInstance.put(`/asset/${id}/comment`, data);
    return resp;
  } catch (err) {
    throw err;
  }
};

const API_DELETE_ASSETS = async (ID) => {
  try {
    const resp = await axiosInstance.delete(`/asset/${ID}`);
    return resp.data;
  } catch (err) {
    throw err;
  }
};

export {
  API_GET_ASSETS,
  API_ADD_ASSETS,
  API_UPDATE_ASSETS,
  API_DELETE_ASSETS,
  API_GET_ASSETSBYID,
  API_UPDATE_ASSETS_COMMENTS,
  API_UPDATE_ASSETS_STATUS,
};
