import axiosInstance from "./axios.instance";
import axios from "axios";

const API_GET_BLOG = async (pageNo, limit, user) => {
  try {
    if (user && (user?.role == "ADMIN" || user?.role == "SUPERADMIN")) {
      const resp = await axiosInstance.get(
        `/blog/admin/all?page=${pageNo}&limit=${limit}`
      );
      return resp.data;
    } else {
      const resp = await axiosInstance.get(
        `/blog?page=${pageNo}&limit=${limit}`
      );
      return resp.data;
    }
  } catch (err) {
    throw err;
  }
};

const API_GET_BLOGBYID = async (id) => {
  console.log(id);
  try {
    const resp = await axiosInstance.get(`/blog/${id}`);
    return resp;
  } catch (err) {
    throw err;
  }
};

const API_ADD_BLOG = async (data) => {
  console.log(data);
  try {
    const resp = await axiosInstance.post(`/blog`, data);
    //console.log(resp);
    return resp;
  } catch (err) {
    throw err;
  }
};

const API_UPDATE_BLOG = async (id, data) => {
  console.log(id, data);
  try {
    const resp = await axiosInstance.put(`/blog/${id}`, data);
    return resp;
  } catch (err) {
    throw err;
  }
};

const API_DELETE_BLOG = async (ID) => {
  try {
    const resp = await axiosInstance.delete(`/blog/${ID}`);
    return resp.data;
  } catch (err) {
    throw err;
  }
};

export {
  API_GET_BLOG,
  API_ADD_BLOG,
  API_UPDATE_BLOG,
  API_DELETE_BLOG,
  API_GET_BLOGBYID,
};
