import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Import Font Awesome component
import { faUpload } from "@fortawesome/free-solid-svg-icons"; // Import edit icon

const CustomImageInput = ({
  register,
  name,
  setValue,
  defaultImage,
  errors,
  validation,
}) => {
  const [imagePreview, setImagePreview] = useState(defaultImage || null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    //console.log(file)
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result); // Set image preview
        setValue(file); // Set value in react-hook-form
      };
      reader.readAsDataURL(file);
    }
  };

  // Set the default image when component mounts or defaultImage changes
  useEffect(() => {
    if (defaultImage) {
     // console.log(defaultImage)
      setImagePreview(defaultImage);
    }
  }, [defaultImage]);

  return (
    <div className="flex flex-col space-y-4">
      {/* Image Upload Box */}
     
      <div className="relative border-2 border-dashed border-gray-300 rounded-lg p-3 flex justify-center items-center cursor-pointer hover:bg-gray-50 transition">
        {/* Image Preview or Upload Icon */}
        {imagePreview ? (
          <div className="relative">
            <img
              src={imagePreview}
              alt="Preview"
              className="h-20 w-full object-cover rounded-md "
            />
            {/* Edit Icon */}
            <div className="w-48 mt-2 lg:mt-0 lg:absolute lg:top-0 lg:right-0 lg:transform lg:translate-x-1/2 lg:-translate-y-1/2 bg-white p-2  rounded-full shadow-md cursor-pointer hover:bg-gray-100 transition">
              <FontAwesomeIcon icon={faUpload} className="text-gray-600" />
              <span className="text-sm ">Click or Drag to Upload</span>
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center text-gray-500 space-y-2">
            <FontAwesomeIcon icon={faUpload} className="text-3xl" />
            <span className="text-sm font-medium">Click or Drag to Upload</span>
          </div>
        )}

        {/* Hidden File Input */}
        <input
          type="file"
          accept="image/*"
          {...register(name, {
            onChange: handleImageChange, // Custom handler for image preview
          })}
          className="absolute inset-0 opacity-0 cursor-pointer"
        />
      </div>

      {/* Validation Error */}
      {errors[name] && (
        <p className="text-red-600 text-sm">{errors[name]?.message}</p>
      )}
    </div>
  );
};

export default CustomImageInput;
