import React from "react";
import "./style.css";

function SectionTop() {
  return (
    <div className="section-top ">
      <video autoPlay loop muted className="background-video">
        <source
          src="https://test-astomverse-developer-assets.s3.ap-south-1.amazonaws.com/community+assets/astomverse+video2.mp4"
          type="video/mp4"
        />
      </video>
      <div className="overlay"></div>
      <div className="content lg:mx-20">
        <div className="mx-8 lg:mx-10 lg:mt-20">
          <p className="text-white font-bold text-2xl lg:text-5xl">
            Bringing Imagination
          </p>
          <p className="text-white font-bold text-2xl lg:text-5xl">
            into Reality
          </p>
          <p className="description">
            Astomverse is an innovative edtech startup, officially recognized by
            Startup India, nurtured within the prestigious incubation ecosystem
            of NIT Patna, and supported & funded by Startup Bihar. The company
            leverages cutting-edge technologies such as Virtual Reality,
            Augmented Reality, Mixed Reality, Spatial Computing, AI, and
            Blockchain to revolutionize the educational landscape.
          </p>
        </div>
      </div>
    </div>
  );
}

export default SectionTop;
