import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const Immerse = () => {
  return (
    <div className="bk">
      <Helmet>
        <title>Immerse - Interactive VR Learning Experience</title>
        <meta
          name="description"
          content="Immerse your students in interactive simulations and experiences that enhance learning and engagement. NCERT-aligned comprehensive VR solutions for effective education."
        />
        <meta
          name="keywords"
          content="VR education, interactive learning, immersive simulations, NCERT-aligned, educational technology"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://astomverse.com/immerse" />
      </Helmet>
      <div className="flex flex-row align-middle justify-between">
        <div className="my-28 lg:my-56 p-5  mx-auto flex flex-col lg:flex-row align-middle justify-evenly">
          <div className="lg:w-6/12">
            <div className="">
              <p className="text-white mb-5 text-5xl lg:text-7xl font-bold">
                Immerse
              </p>
              <p className="text-white lg:text-3xl mt-1">
                Captivates student interest with interactive simulations and
                experiences that go beyond static smart board presentations.
              </p>

              <div className="flex flex-col lg:flex-row gap-7 mt-5 justify-start align-middle ">
                <div className=" align-middle p-3 outline-1 rounded-full px-5 text-sm outline-purple-300  outline">
                  <p className="text-white font-bold">
                    User-Friendly Interface
                  </p>
                </div>
                <div className=" align-middle p-3 outline-1 rounded-full px-5 text-sm outline-purple-300  outline">
                  <p className="text-white font-bold">NCERT-Aligned</p>
                </div>
                <div className=" align-middle p-3 outline-1 rounded-full px-5 text-sm outline-purple-300  outline">
                  <p className="text-white font-bold">Comprehensive Content</p>
                </div>
              </div>
              <p className="text-zinc-400 lg:mx-4 mt-4  ">
                Our Mission is to revolutionize education by providing
                interactive and engaging VR solutions that make complex concepts
                easier to understand and retain.
              </p>
              <div className="flex justify-start  my-10 ">
                <Link
                  to={"#"}
                  className="lg:ml-3 w-full lg:w-4/12 flex justify-center  font-bold p-3 px-8 rounded-full text-white bg-gradient-to-r from-violet-500 to-fuchsia-500"
                >
                  Upcoming product
                </Link>
              </div>
            </div>
          </div>
          <div className="lg:w-3/12  flex mx-auto lg:mx-0 lg:justify-start">
            <video
              autoPlay
              loop
              muted
              className="lg:h-[520px] h-[580px]  outline-1 rounded-3xl outline-gray-600  outline"
            >
              <source
                src="https://test-astomverse-developer-assets.s3.ap-south-1.amazonaws.com/community+assets/immerse1.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Immerse;
