import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../Loader";
import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  addBlog,
  resetMode,
  resetSelectedBlog,
  updateBlog,
} from "../../reducers/blogSlice";
import CustomImageInput from "../CustomImageInput";

const NewBlogForm = () => {
  const navigate = useNavigate();
  const { loading, selectedBlog, mode } = useSelector(
    (state) => state.blogSlice
  );
  const dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      title: "",
      description: "",
      imageUrl: "",
      content: "",
      metaTitle: "",
      metaDescription: "",
      metaKeywords: "",
      robots: "",
    },
  });

  useEffect(() => {
   // console.log(selectedBlog);
    if (selectedBlog) {
      reset({
        title: selectedBlog?.title || "",
        description: selectedBlog?.description || "",
        imageUrl: selectedBlog?.images[0]?.url || "",
        content: selectedBlog?.content || "",
        metaTitle: selectedBlog?.meta?.title || "",
        metaDescription: selectedBlog?.meta?.description || "",
        metaKeywords: selectedBlog?.meta?.keywords?.join(", ") || "",
        robots: selectedBlog?.meta?.robots || "",
        isPublished: selectedBlog?.isPublished,
      });
    }
  }, [selectedBlog, reset]);

  const showMsg = (message) => {
    toast(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
    });
  };

  const handelCancel = () => {
    dispatch(resetMode());
    dispatch(resetSelectedBlog());
    reset();
    navigate("/blog");
  };

  const onSubmit = (data) => {
    //console.log(data, selectedImage);

    const keywordsArray = data?.metaKeywords
      .split(",")
      .map((keyword) => keyword.trim()); // Split keywords by commas and trim spaces
    const blog = {
      title: data?.title,
      slug: data?.title,
      description:data.description,
      content: data?.content,
      isPublished: data.isPublished,
      meta: {
        title: data?.metaTitle,
        description: data?.metaDescription,
        keywords: keywordsArray,
        robots: data?.robots, // Collect robots value from select input
      },
    };

    if (mode === "update" && selectedImage) {
     // console.log("update blog with image");
      dispatch(updateBlog({ selectedBlog, data: blog, selectedImage }))
        .unwrap()
        .then((res) => {
          //reset();
          dispatch(resetMode());
          dispatch(resetSelectedBlog());
          showMsg("Blog Update Successfully");
          setTimeout(() => {
            navigate("/blog");
          }, 1000);
          return;
        })
        .catch((err) => {
          showMsg(err.error);
          return;
        });
    } else if (mode === "update") {
      //console.log("update blog");
      dispatch(updateBlog({ selectedBlog, data: blog, selectedImage: null }))
        .unwrap()
        .then((res) => {
          //reset();
          dispatch(resetMode());
          dispatch(resetSelectedBlog());
          showMsg("Blog Update Successfully");
          setTimeout(() => {
            navigate("/blog");
          }, 1000);
          return;
        })
        .catch((err) => {
          showMsg(err.error);
          return;
        });
    } else {
      if (!selectedImage) {
        showMsg("Please upload a image");
        return;
      }
      if (selectedImage) {
        dispatch(addBlog({ data: blog, selectedImage }))
          .unwrap()
          .then((res) => {
            reset();
            showMsg("Blog Added Successfully");
            setTimeout(() => {
              navigate("/blog");
            }, 1000);
          })
          .catch((err) => {
            showMsg(err.error);
          });
      }
    }
  };

  const modalLoad = () => {
    return <Loader visible={loading} />;
  };

  const metaTitle = watch("metaTitle", "");
  const metaDescription = watch("metaDescription", "");

  return (
    <div className="flex w-full items-center justify-center min-h-screen  p-4 sm:p-6">
      {loading && modalLoad()}
      {toast && <ToastContainer />}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-6 flex gap-2 lg:gap-0 lg:flex-col flex-col-reverse w-full lg:mx-16"
      >
        {/* Submit and Cancel Buttons */}
        <div className="flex justify-end gap-6  bg-gray-200 rounded-lg p-2">
          <button
            type="button"
            onClick={() => {
              handelCancel();
            }}
            className="w-full sm:w-auto font-bold py-2 px-6 rounded-md border-2 border-gray-400 text-gray-600 hover:bg-gray-200 transition duration-150"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={loading}
            className={`w-full sm:w-auto flex justify-center items-center font-bold py-2 px-8 rounded-md text-white bg-gray-600 hover:bg-gray-700 transition duration-150 ${
              loading ? "cursor-not-allowed" : ""
            }`}
          >
            {mode === "update" ? " Edit " : "+ Add"}
          </button>
        </div>
        {/* Form Body */}
        <div className="w-full flex flex-col lg:flex-row gap-6 lg:gap-20  bg-white rounded-2xl shadow-sm p-6 sm:p-8 space-y-8 transition transform ">
          {/* Left Column (Main Blog Data) */}
          <div className="w-full lg:w-3/4 mt-0 lg:mt-8 space-y-6">
            {/* Blog Title */}
            <div className="space-y-2">
              <label
                className="block text-lg font-semibold text-gray-700"
                htmlFor="title"
              >
                Blog Title
              </label>
              <input
                type="text"
                id="title"
                {...register("title", {
                  required: "Title is required",
                  minLength: {
                    value: 5,
                    message: "Title must be at least 5 characters",
                  },
                })}
                className={`w-full px-4 py-3 bg-gray-50 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition duration-150 ${
                  errors.title ? "border-red-500" : ""
                }`}
                placeholder="Enter blog title"
              />
              {errors.title && (
                <span className="text-red-600 text-sm">
                  {errors.title.message}
                </span>
              )}
            </div>

            {/* Blog Description */}
            <div className="space-y-2">
              <label
                className="block text-lg font-semibold text-gray-700"
                htmlFor="description"
              >
                Blog Description
              </label>
              <input
                type="text"
                id="description"
                {...register("description", {
                  required: "Description is required",
                  minLength: {
                    value: 10,
                    message: "Description must be at least 10 characters",
                  },
                })}
                className={`w-full px-4 py-3 bg-gray-50 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition duration-150 ${
                  errors.description ? "border-red-500" : ""
                }`}
                placeholder="Enter blog description"
              />
              {errors.description && (
                <span className="text-red-600 text-sm">
                  {errors.description.message}
                </span>
              )}
            </div>

            {/* Blog Content */}
            <div className="space-y-5">
              <label
                className="block text-lg font-semibold text-gray-700"
                htmlFor="content"
              >
                Blog Content
              </label>
              <textarea
                id="content"
                {...register("content", {
                  required: "Content is required",
                  minLength: {
                    value: 50,
                    message: "Content must be at least 50 characters",
                  },
                })}
                className={`w-full px-4 py-3 bg-gray-50 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition duration-150 ${
                  errors.content ? "border-red-500" : ""
                }`}
                placeholder="Enter blog content"
                rows="30"
              />
              {errors.content && (
                <span className="text-red-600 text-sm">
                  {errors.content.message}
                </span>
              )}
            </div>
          </div>

          {/* Right Column (Meta Data) */}
          <div className="w-full lg:w-1/4 space-y-6">
            <div>
              <label
                className="block mb-2 text-lg font-semibold text-gray-700"
                htmlFor="imageUrl"
              >
                Blog Image
              </label>
              <CustomImageInput
                name="imageUrl"
                register={register}
                setValue={setSelectedImage}
                errors={errors}
                defaultImage={
                  selectedBlog?.images[0]?.url
                    ? selectedBlog?.images[0]?.url
                    : "http://localhost:3000/ImgPlaceholder.png"
                }
                validation={{
                  required: "Image is required",
                }}
              />
            </div>
            {/* Meta Title */}
            <div className="space-y-2">
              <label
                className="block text-lg font-semibold text-gray-700"
                htmlFor="metaTitle"
              >
                Meta Title
              </label>
              <input
                type="text"
                id="metaTitle"
                {...register("metaTitle", {
                  required: "Meta Title are required",
                  maxLength: {
                    value: 60,
                    message: "Meta Title cannot exceed 60 characters",
                  },
                })}
                className={`w-full px-4 py-3 bg-gray-50 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition duration-150 ${
                  errors.content ? "border-red-500" : ""
                }`}
                placeholder="Enter meta title"
              />
              <p className="text-sm text-gray-500">
                {metaTitle.length}/60 characters
              </p>
              {errors.metaTitle && (
                <span className="text-red-600 text-sm">
                  {errors.metaTitle.message}
                </span>
              )}
            </div>

            {/* Meta Description */}
            <div className="space-y-2">
              <label
                className="block text-lg font-semibold text-gray-700"
                htmlFor="metaDescription"
              >
                Meta Description
              </label>
              <textarea
                id="metaDescription"
                {...register("metaDescription", {
                  required: "Meta Description are required",
                  maxLength: {
                    value: 160,
                    message: "Meta Description cannot exceed 160 characters",
                  },
                })}
                className={`w-full px-4 py-3 bg-gray-50 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition duration-150 ${
                  errors.content ? "border-red-500" : ""
                }`}
                placeholder="Enter meta description"
                rows="4"
              />
              <p className="text-sm text-gray-500">
                {metaDescription.length}/160 characters
              </p>
              {errors.metaDescription && (
                <span className="text-red-600 text-sm">
                  {errors.metaDescription.message}
                </span>
              )}
            </div>

            {/* Meta Keywords */}
            <div>
              <label
                className="block text-lg font-semibold text-gray-700"
                htmlFor="metaKeywords"
              >
                Meta Keywords (comma-separated)
              </label>
              <input
                type="text"
                {...register("metaKeywords", {
                  required: "Meta Keywords are required",
                })}
                className={`w-full px-4 py-3 bg-gray-50 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition duration-150 ${
                  errors.content ? "border-red-500" : ""
                }`}
                placeholder="Enter keywords separated by commas"
              />
              {errors.metaKeywords && (
                <span className="text-red-600 text-sm">
                  {errors.metaKeywords.message}
                </span>
              )}
            </div>

            {/* Robots Dropdown */}
            <div className="space-y-2">
              <label
                className="block text-lg font-semibold text-gray-700"
                htmlFor="robots"
              >
                Meta Robots
              </label>
              <select
                id="robots"
                {...register("robots", {
                  required: "Please select a valid robots directive",
                })}
                className={`w-full px-4 py-3 bg-gray-50 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition duration-150 ${
                  errors.content ? "border-red-500" : ""
                }`}
              >
                <option value="">Select robots directive</option>
                <option value="index, follow">Index, Follow</option>
                <option value="noindex, follow">Noindex, Follow</option>
                <option value="index, nofollow">Index, Nofollow</option>
                <option value="noindex, nofollow">Noindex, Nofollow</option>
              </select>
              {errors.robots && (
                <span className="text-red-600 text-sm">
                  {errors.robots.message}
                </span>
              )}
            </div>

            {/* Is Published Checkbox */}
            <div className="space-y-2">
              <label className="flex items-center group cursor-pointer">
                <input
                  type="checkbox"
                  {...register("isPublished")}
                  className="h-5 w-5 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500 transition duration-150 ease-in-out"
                />
                <span className="ml-3 text-md font-semibold text-gray-700  ">
                  Publish Blog
                </span>
              </label>

              {errors.isPublished && (
                <span className="text-sm text-red-600">
                  {errors.isPublished.message}
                </span>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default NewBlogForm;
