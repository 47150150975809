import React from "react";
import "./style.css";
import { Link } from "react-router-dom";

const SectionMiddle = () => {
  return (
    <div className="w-full  bk lg:pt-8 ">
      <div className="py-12 gap-5 lg:gap-0  flex flex-col lg:flex-row justify-center align-middle outline-1 rounded-full outline-gray-700  lg:outline lg:outline-y lg:mx-28">
        <div className="mx-auto lg:mx-0 mb-4 lg:my-auto lg:border-r  lg:px-8 lg:py-5">
          <span className="text-slate-200 font-bold text-1xl lg:text-2xl">
            Supported & Recognized By
          </span>
        </div>
        <div className="flex lg:gap-2 justify-center align-middle mx-1 lg:mx-2">
          <div className="my-auto ">
            <img
              src="./startupindia.png"
              alt="log"
              className="ml-2 lg:ml-5 w-56 mt-5 lg:mt-10  mb-4"
            />
          </div>
          <div className="my-auto ">
            <img src="./startup-bihar.png" alt="log" className=" w-56  mb-4" />
          </div>
          <div className="my-auto  ">
            <img src="./nitp.png" alt="log" className=" w-28" />
          </div>
          <div className="my-auto mr-1 lg:mr-5 lg:mr-0 ml-10 rounded-full">
            <img src="./ic.jpg" alt="log" className=" w-28 rounded-full" />
          </div>
        </div>
      </div>
      <div className="gap-4 mx-5  py-14 lg:py-64 pb-10 flex flex-col justify-center align-middle ">
        <div className="flex justify-center align-middle">
          <span className="text-zinc-100  font-bold  lg:text-4xl">
            Transforming & Revolutionize Education
          </span>
        </div>
        <div className="lg:w-4/12 lg:mt-3 mx-auto flex justify-center align-middle">
          <span className="text-zinc-300 text-center text-1xl">
            Astomverse integrates cutting-edge VR technology into education,
            offering both a robust VR product and a versatile educational
            application:
          </span>
        </div>

        <div className="lg:w-9/12 mx-auto my-8 lg:my-20 flex flex-col lg:flex-row justify-evenly align-middle gap-5">
          <div className=" p-5 lg:px-8 flex flex-col justify-center align-middle outline-1 rounded-3xl lg:rounded-full outline-purple-300  outline">
            <span className="text-white mx-auto text-md font-bold border-b border-zinc-500">
              Immersive 3D Models
            </span>
            <span className="text-slate-100 text-center mt-2 mx-auto font-extralight">
              Detailed exploration of complex scientific concepts.
            </span>
          </div>
          <div className=" p-5 lg:px-8 flex flex-col justify-center align-middle outline-1 rounded-3xl lg:rounded-full outline-purple-300  outline">
            <span className="text-white mx-auto text-md font-bold border-b border-zinc-500">
              Virtual Experiments
            </span>
            <span className="text-slate-100 text-center mt-2 mx-auto font-extralight">
              Hands-on learning in a safe, virtual environment.
            </span>
          </div>
          <div className=" p-5 lg:px-8 flex flex-col justify-center align-middle outline-1 rounded-3xl lg:rounded-full outline-purple-300  outline">
            <span className="text-white mx-auto text-md font-bold border-b border-zinc-500">
              Interactive Simulations
            </span>
            <span className="text-slate-100 text-center mt-2 mx-auto font-extralight">
              Engaging activities that enhance understanding.
            </span>
          </div>
        </div>
      </div>

      <div className="lg:w-10/12 lg:my-28 flex flex-col-reverse lg:flex-row align-middle justify-between lg:mx-auto p-5 lg:p-10  outline-1 rounded-3xl outline-gray-800 lg:outline ">
        <div className="lg:w-6/12 mt-10 lg:mt-0">
          <div className="">
            <p className="text-white mb-5 text-3xl border-gray-400 lg:border-y w-28 font-bold">
              VRClass
            </p>
            <p className="text-white text-1xl lg:text-2xl lg:font-bold mt-1 ">
              We specialize in developing immersive 3D models and virtual
              reality (VR) experiments to enhance the learning experience for
              students in classes 6th to 12th.
            </p>

            <div className="flex flex-col lg:flex-row gap-3 mt-5 justify-center align-middle ">
              <div className=" align-middle p-3 lg:p-3 outline-1 rounded-full px-5 text-sm outline-purple-300  outline">
                <p className="text-white  lg:font-bold">
                  Accessible Educational Tools
                </p>
              </div>
              <div className=" align-middle p-3 lg:p-3 outline-1 rounded-full px-5 text-sm outline-purple-300  outline">
                <p className="text-white  lg:font-bold">
                  Engaging Interactive Content
                </p>
              </div>
              <div className=" align-middle p-3 lg:p-3 outline-1 rounded-full px-5 text-sm outline-purple-300  outline">
                <p className="text-white  lg:font-bold">
                  Consistent Learning Outcomes
                </p>
              </div>
            </div>
            <p className="text-zinc-400 text-sm lg:text-1xl  lg:mx-4 mt-4  ">
              Our Mission is to revolutionize education by providing interactive
              and engaging VR solutions that make complex concepts easier to
              understand and retain.
            </p>
            <div className="mt-12  flex">
              <Link
                to={"vrclass"}
                className="lg:ml-3 w-full lg:w-4/12 flex justify-center hover:from-violet-600 hover:to-fuchsia-600  font-bold p-3 px-8 rounded-full text-white bg-gradient-to-r from-violet-500 to-fuchsia-500"
              >
                explore more
              </Link>
            </div>
          </div>
        </div>
        <div className="lg:w-6/12  flex justify-end">
          <video
            autoPlay
            loop
            muted
            className=" lg:w-[260px ] lg:h-[480px]  outline-1 rounded-3xl outline-gray-700  outline"
          >
            <source
              src="https://test-astomverse-developer-assets.s3.ap-south-1.amazonaws.com/community+assets/astomverse+video2.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      </div>

      <div className="lg:w-10/12 mx-5 lg:mx-auto p-2 lg:p-10  outline-1 rounded-3xl outline-gray-800  lg:outline my-10 lg:my-28 flex flex-col lg:flex-row align-middle justify-between">
        <div className=" g:w-3/12 mx-4 flex justify-start">
          <video
            autoPlay
            loop
            muted
            className="lg:h-[520px]  outline-1 rounded-3xl outline-gray-600  outline"
          >
            <source
              src="https://test-astomverse-developer-assets.s3.ap-south-1.amazonaws.com/community+assets/immerse1.mp4"
              type="video/mp4"
            />
          </video>
        </div>
        <div className="lg:w-9/12 mt-8 lg:mt-0">
          <div className="">
            <p className="text-white mb-5 text-3xl border-gray-400 lg:border-y w-28 font-bold">
              Immerse
            </p>
            <p className="text-white text-1xl lg:text-2xl lg:font-bold mt-1 ">
              Captivates student interest with interactive simulations and
              experiences that go beyond static smart board presentations.
            </p>

            <div className="flex flex-col lg:flex-row gap-3 mt-5 justify-center lg:justify-start align-middle">
              <div className=" align-middle p-3 outline-1 rounded-full px-5 text-sm outline-purple-300  outline">
                <p className="text-white font-bold">User-Friendly Interface</p>
              </div>
              <div className=" align-middle p-3 outline-1 rounded-full px-5 text-sm outline-purple-300  outline">
                <p className="text-white font-bold">NCERT-Aligned</p>
              </div>
              <div className=" align-middle p-3 outline-1 rounded-full px-5 text-sm outline-purple-300  outline">
                <p className="text-white font-bold">Comprehensive Content</p>
              </div>
            </div>
            <p className="text-zinc-400 mx-2 mt-4  ">
              Our Mission is to revolutionize education by providing interactive
              and engaging VR solutions that make complex concepts easier to
              understand and retain.
            </p>
            <div className="mt-12 flex">
              <Link
                to={"immerse"}
                className="lg:ml-3 w-full lg:w-3/12 flex justify-center hover:from-violet-600 hover:to-fuchsia-600  font-bold p-3 px-8 rounded-full text-white bg-gradient-to-r from-violet-500 to-fuchsia-500"
              >
                explore more
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="w-10/12 mx-auto py-16 lg:py-32 flex flex-col justify-center align-middle gap-4">
        <div className="flex justify-center  lg:py-4 lg:pt-3 align-middle lg:border-y border-zinc-500">
          <span className="text-zinc-100  font-bold text-2xl lg:text-4xl ">
            Team
          </span>
        </div>
        <div className="flex justify-center align-middle">
          <img
            src="./astomverse team.jpg"
            alt="log"
            className=" outline-1 rounded-3xl outline-purple-300  outline"
          />
        </div>
      </div>
    </div>
  );
};

export default SectionMiddle;
