import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import Home from "./view/Home";
import About from "./view/About";
import Community from "./view/Community";
import Support from "./view/Support";
import VRCLass from "./view/Products/VRClass";
import Immerse from "./view/Products/Immerse";
import Error from "./view/Error";
import Blog from "./view/Blog";
import BlogDetail from "./view/BlogDetail";
import ScrollToTop from "./components/ScrollToTop";
import AddNewBlog from "./admin/AddNewBlog";
import AdminLogin from "./admin/Login";
import { useSelector, useDispatch } from "react-redux";
import parseJwt from "./utils/authUtils";
import { setUser, logout,resetUser } from "./reducers/authSlice";
import { useEffect } from "react";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname;
    const token = localStorage.getItem("token");

    if (token && token !== "undefined") {
      try {
        const user = parseJwt(token);

        // Check for token expiry or validity
        const currentTime = Math.floor(Date.now() / 1000);
        if (user.exp && user.exp < currentTime) {
          // Token expired
          throw new Error("Token expired");
        }

        dispatch(setUser(user));
      } catch (error) {
        // If the token is invalid or expired, clear it and log the user out
        localStorage.removeItem("token");
        dispatch(resetUser());
        navigate("/admin-login");
      }
    }
  }, [location.pathname, dispatch, navigate]);

  const { isLogedIn, user, loading } = useSelector(
    (state) => state.authenticate
  );

  const AdminRoute = ({ children }) => {
    // Add role-based security to check if the user is an admin
    return isLogedIn && user?.role === "ADMIN" ? children : <Navigate to="/" />;
  };

  return (
    <div className="App">
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />}>
          <Route path="about" element={<About />} />
          <Route path="vrclass" element={<VRCLass />} />
          <Route path="immerse" element={<Immerse />} />
          <Route path="Support" element={<Support />} />
          <Route path="community" element={<Community />} />
          <Route path="blog" element={<Blog />} />
          <Route path="blog/:_id" element={<BlogDetail />} />
          <Route path="admin-login" element={<AdminLogin />} />
          <Route
            path="admin/add-blog"
            element={
              <AdminRoute>
                <AddNewBlog />
              </AdminRoute>
            }
          />
        </Route>
        <Route path="*" element={<Error />} />
      </Routes>
    </div>
  );
}

export default App;
