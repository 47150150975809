import axios from "axios";
import baseUrl from "./server";
import { logout } from "../reducers/authSlice";
import store from '../store';

const axiosInstance = axios.create({
    baseURL: baseUrl,
    headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
    },
});



axiosInstance.interceptors.response.use(function (response) {
    //console.log("res",response)
    return response;
  }, function (error) {
    // Do something with response error
   // console.log("err",error.response.data)
    if (error) {
        if (error.response.data.message == "Authorization error") {
          localStorage.clear()
        }
    }
    return Promise.reject(error);
  });

if(localStorage.getItem("token")){
    var token = localStorage.getItem("token")
    axiosInstance.defaults.headers.common["authorization"] = token;
}

export default axiosInstance;