import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { resetUser, setUser } from "../../reducers/authSlice";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Import Font Awesome component
import { faUser } from "@fortawesome/free-solid-svg-icons"; // Import user icon
import parseJwt from "../../utils/authUtils";
const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sidebarRef = useRef(null); // Reference to sidebar

  // Get the user and login state from Redux
  const { isLogedIn, user } = useSelector((state) => state.authenticate);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  // Toggle the mobile menu
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // useEffect(() => {
  //   console.log(user);
  // }, []);

  // Handle logout
  const handleLogout = () => {
    dispatch(resetUser());
    localStorage.clear();
    navigate("/"); // Redirect to login page
  };

  const NavBarItem = ({ item, link }) => (
    <div className="nav-item p-2 rounded">
      <Link
        to={link}
        onClick={isOpen?toggleMenu:toggleMenu}
        className={`text-white font-semibold block transition-colors duration-200 ${
          pathname === `/${link}` ? "underline font-bold" : ""
        }`}
      >
        {item}
      </Link>
    </div>
  );

  return (
    <header className="header w-full z-10 fixed bg-gray-900 shadow-lg">
      {/* Main Header Container */}
      <div className="mx-4 lg:ml-28 lg:mx-14 py-3 flex justify-between items-center">
        {/* Logo */}
        <div className="flex items-center">
          <Link to="/" className="flex items-center">
            <img
              src="https://www.astomverse.com/av.png"
              alt="logo"
              className="w-8 lg:w-10"
            />
            <p className="text-white font-bold text-xl lg:text-2xl ml-2">
              Astomverse
            </p>
          </Link>
        </div>

        {/* Desktop Menu */}
        <nav className="hidden lg:flex space-x-8 items-center">
          <NavBarItem item={"VRClass"} link={"vrclass"} />
          <NavBarItem item={"Immerse"} link={"immerse"} />
          <NavBarItem item={"Blog"} link={"blog"} />
          <NavBarItem item={"Community"} link={"community"} />

          {/* Username, Icon, and Logout for Desktop */}
          {isLogedIn && (
            <div className="flex items-center space-x-4">
              {/* User Icon */}
              <FontAwesomeIcon icon={faUser} className="text-white text-lg" />
              <span className="text-white font-semibold">
                Hello, {user?.userName}
              </span>
              <button
                onClick={handleLogout}
                className="text-white font-semibold bg-red-500 px-3 py-1 rounded-lg"
              >
                Logout
              </button>
            </div>
          )}
        </nav>

        {/* Hamburger Icon for Mobile */}
        <button
          onClick={toggleMenu}
          className="lg:hidden flex items-center focus:outline-none"
        >
          <svg
            className="w-8 h-8 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </button>
      </div>

      {/* Mobile Sidebar Navigation */}
      <div
        ref={sidebarRef}
        className={`lg:hidden fixed top-0 right-0 w-64 h-screen bg-gray-900 shadow-lg z-50 transform ${
          isOpen ? "translate-x-0" : "translate-x-full"
        } transition-transform duration-300 ease-in-out`}
      >
        <div className="px-6 py-4 flex items-center justify-between border-b border-gray-700">
          <span className="text-white mt-1 text-1xl font-bold">Menu</span>
          <button
            onClick={toggleMenu}
            className="text-white text-3xl focus:outline-none"
          >
            &times;
          </button>
        </div>

        <nav className="flex flex-col p-6 space-y-4">
          {/* Username and Icon for Mobile */}
          {isLogedIn && (
            <div className="flex items-center space-x-2 mb-4">
              <FontAwesomeIcon icon={faUser} className="text-white text-lg" />
              <span className="text-white font-semibold">
                Hello, {user?.userName}
              </span>
            </div>
          )}
          <NavBarItem item={"VRClass"} link={"vrclass"} />
          <NavBarItem item={"Immerse"} link={"immerse"} />
          <NavBarItem item={"Blog"} link={"blog"} />
          <NavBarItem item={"Community"} link={"community"} />

          {/* Logout Button for Mobile */}
          {isLogedIn && (
            <button
              onClick={handleLogout}
              className="text-white font-semibold bg-red-500 px-3 py-2 rounded-lg mt-4"
            >
              Logout
            </button>
          )}
        </nav>
      </div>
    </header>
  );
};

export default Header;
