
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  API_GET_ASSETS,
  API_ADD_ASSETS,
  API_DELETE_ASSETS,
  API_UPDATE_ASSETS,
  API_UPDATE_ASSETS_COMMENTS,
  API_UPDATE_ASSETS_STATUS,
} from "../api/assets.service";
import { updateTableData, removeDeleteData } from "../utils/tableUtils";

const initialState = {
  loading: false,
  assetsData: [],
  totalAssetsCount: 0,
  selectedAssets: null,
  page: 0,
  limit: 5,
  mode: null,
};

export const getAssets = createAsyncThunk(
  "assetsTable/getAssetsList",
  async ({ page, limit,filter }, thunkAPI) => {
    //console.log(page, limit);
    try {
      const assetsdata = await API_GET_ASSETS(page, limit,filter);
      return assetsdata;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const addAssets = createAsyncThunk(
  "assetsTable/addAssets",
  async (data, thunkAPI) => {
    try {
      //console.log(data);
      const Assets = await API_ADD_ASSETS(data);
      return Assets;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const updateAssets = createAsyncThunk(
  "assetsTable/updateAssets",
  async ({ id, data }, thunkAPI) => {
    try {
      const Assets = await API_UPDATE_ASSETS_STATUS(id, data);
      return Assets;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const updateAssetsComment = createAsyncThunk(
  "assetsTable/updateAssetsComment",
  async ({ id, data }, thunkAPI) => {
    try {
      const Assets = await API_UPDATE_ASSETS_COMMENTS(id, data);
      return Assets;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const deleteAssets = createAsyncThunk(
  "assetsTable/deleteAssets",
  async (data, thunkAPI) => {
    //console.log(data);
    try {
      const Assets = await API_DELETE_ASSETS(data);
      return Assets;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

const assetsTableSlice = createSlice({
  name: "assetsTableTable",
  initialState,
  reducers: {
    changeMode(state, action) {
      state.mode = action.payload;
    },
    resetMode(state) {
      state.mode = null;
    },
    changeSelectedAssets(state, action) {
      state.selectedAssets = action.payload;
    },
    resetSelectedAssets(state) {
      state.selectedAssets = null;
    },
    changePage(state, action) {
      state.page = action.payload;
    },
    changeLimit(state, action) {
      state.limit = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getAssets.fulfilled, (state, action) => {
      state.totalAssetsCount = action.payload.count;
      state.assetsData = action.payload.rows;
      state.loading = false;
    });
    builder.addCase(getAssets.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAssets.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(addAssets.fulfilled, (state, action) => {
      let data = action.payload.data;
      console.log(data);
      if (state.assetsData.length < state.limit) {
        state.assetsData = [...state.assetsData, data];
      } else {
        state.assetsData = [...state.assetsData.slice(0, state.limit - 1), data];
      }
      state.totalAssetsCount += 1;
      state.loading = false;
    });
    builder.addCase(addAssets.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addAssets.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateAssets.fulfilled, (state, action) => {
      console.log(action.payload.data);
      state.assetsData = updateTableData(state.assetsData, action.payload.data);
      state.loading = false;
    });
    builder.addCase(updateAssets.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateAssets.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateAssetsComment.fulfilled, (state, action) => {
      console.log(action.payload.data);
      state.loading = false;
    });
    builder.addCase(updateAssetsComment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateAssetsComment.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(deleteAssets.fulfilled, (state, action) => {
      console.log(action.payload);
      state.totalAssetsCount -= 1;
      state.assetsData = removeDeleteData(state.assetsData, action.payload.id);
      state.loading = false;
      state.mode = null;
    });
    builder.addCase(deleteAssets.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteAssets.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  changeMode,
  resetMode,
  changeSelectedAssets,
  resetSelectedAssets,
  changePage,
  changeLimit,
} = assetsTableSlice.actions;

export default assetsTableSlice.reducer;