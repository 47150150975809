import React from "react";
import "./style.css";
import { Link, useLocation } from "react-router-dom";
import ContactUs from "../ContactUs";

const Footer = () => {
  const NavBarItem = ({ item, link }) => {
    const { pathname } = useLocation();
    return (
      <div className="lg:w-24 flex justify-center ">
        <Link
          to={link}
          className={
            pathname == `/${link}`
              ? "text-white underline font-bold"
              : `text-white font-semibold hover:font-bold `
          }
        >
          {item}
        </Link>
      </div>
    );
  };

  return (
    <div className="footer w-full bg-zinc-900">
      <div className="flex flex-col-reverse lg:flex-row justify-between py-14 mx-5 lg:mx-10">
        <div className="w-full lg:w-4/12 flex flex-col lg:flex-col align-middle justify-center lg:justify-start">
          <div className="lg:mx-0 lg:mt-0 mt-5 flex flex-row justify-start align-middle ">
            <Link to="/">
              <img
                src="https://www.astomverse.com/av.png"
                alt="log"
                className="w-12  lg:w-14"
              />
            </Link>
            <p className="text-white font-bold text-2xl mt-2 lg:mt-4 lg:text-2xl">
              Astomverse
            </p>
          </div>
          <div className="mt-5">
            <p className=" text-gray-200">
              Astomverse is an innovative edtech startup, officially recognized
              by Startup India, nurtured within the prestigious incubation
              ecosystem of NIT Patna, and supported & funded by Startup Bihar.
              The company leverages cutting-edge technologies such as Virtual
              Reality, Augmented Reality, Mixed Reality, Spatial Computing, AI,
              and Blockchain to revolutionize the educational landscape.
            </p>
          </div>
          <div className="mt-5">
            <span className="text-gray-400 font-bold">Address: </span>
            <span className="text-gray-400">
              J5CC+6FC, Patna University Campus, Patna, Bihar 800006
            </span>
          </div>
          <div className="py-10 flex justify-center lg:justify-start align-middle">
            <div className="flex gap-5 lg:gap-10 justify-center align-middle">
              <div className="my-auto ">
                <Link
                  to={"https://www.linkedin.com/company/astomverse"}
                  target="_blank"
                >
                  <img
                    src="https://img.icons8.com/color/48/linkedin.png"
                    alt="linkedin"
                    className="w-10 lg:w-16"
                  />
                </Link>
              </div>
              <div className="my-auto  ">
                <Link
                  to={"https://www.facebook.com/astomverse"}
                  target="_blank"
                >
                  <img
                    src="https://img.icons8.com/color/48/facebook.png"
                    alt="facebook"
                    className="w-10 lg:w-16"
                  />
                </Link>
              </div>
              <div className="my-auto">
                <Link
                  to={"https://www.instagram.com/astomverse/?next=%2F&hl=en"}
                  target="_blank"
                >
                  <img
                    src="https://img.icons8.com/3d-fluency/94/instagram-new.png"
                    alt="instagram-new"
                    className="w-10 lg:w-16"
                  />
                </Link>
              </div>
              <div className="my-auto">
                <Link
                  to={"https://www.youtube.com/@astom-verse"}
                  target="_blank"
                >
                  <img
                    src="https://img.icons8.com/fluency/48/youtube-play.png"
                    alt="youtube-play"
                    className="w-10 lg:w-16 "
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full lg:w-4/12  flex justify-center align-middle ">
          <ContactUs />
        </div>
      </div>
    </div>
  );
};

export default Footer;
