import React from "react";
import { Helmet } from "react-helmet-async";

const Community = () => {
  // Sample data for community member projects (replace with actual data)
  const memberWorks = [
    {
      id: 1,
      image:
        "https://test-astomverse-developer-assets.s3.ap-south-1.amazonaws.com/community+assets/0e4c80cc-b335-4815-837d-1ee8cc25001b.jfif", // replace with actual image URL
      title: "Flower Pot",
      description:
        "This beautifully crafted flower pot, designed in Blender, showcases a modern aesthetic with smooth curves and a sleek finish.Additionally, this flower pot is optimized for virtual reality (VR) environments, enabling users to interact with and manipulate the pot in immersive 3D spaces. Experience the beauty of gardening like never before, as you explore and arrange plants within a virtual setting.",
      memberName: "Wagishi Jagat",
      branch: "Electrical engineering",
    },
    {
      id: 2,
      image:
        "https://test-astomverse-developer-assets.s3.ap-south-1.amazonaws.com/community+assets/ca3adb7d-c743-45de-9fd9-f4bbd4f6ac87.jfif", // replace with actual image URL
      title: "Metaverse Architecture",
      description:
        "This intricately designed city, created in Blender, immerses viewers in a vibrant urban environment that showcases stunning architectural diversity and dynamic street life. The city features a blend of modern skyscrapers, quaint shops, and lush green spaces, all meticulously crafted with attention to detail. Each building boasts realistic textures and dynamic lighting that breathe life into the virtual landscape.",
      memberName: "Ritesh Verma",
      branch: "Mechanical engineering",
    },
    {
      id: 3,
      image:
        "https://test-astomverse-developer-assets.s3.ap-south-1.amazonaws.com/community+assets/Screenshot+2024-10-14+141124.png", // replace with actual image URL
      title: "Crafted Room",
      description:
        "This meticulously crafted room, designed in Blender, offers an immersive environment that showcases contemporary design and thoughtful details. The space features stylish furniture, elegant decor, and realistic lighting that create a warm and inviting atmosphere. Textures are finely detailed, from the soft fabric of the cushions to the intricate patterns on the wallpaper, providing a sensory-rich experience.",
      memberName: "Niraj Kumar",
      branch: "Computer Science",
    },
  ];

  const handleJoinCommunity = () => {
    window.open("https://forms.gle/ag634SbKkmJgQug98", "_blank"); // Replace with your Google Form URL
  };

  return (
    <div className="bk">
      <Helmet>
        <title>Astomverse Community - Join Us!</title>
        <meta
          name="description"
          content="Join the Astomverse Community to explore the future of technology and innovation."
        />
        <meta
          name="keywords"
          content="Astomverse, community, technology, innovation, NIT Patna"
        />
        <meta name="author" content="Niranjan Kumar" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://astomverse.com/community" />
      </Helmet>

      <div className="flex flex-col align-middle justify-between lg:w-10/12 mx-auto p-5 lg:p-10  ">
        <div className="mt-20">
          <div className="w-full flex lg:flex-row flex-col gap-5">
            <div className="mb-10">
              <h1 className="text-3xl lg:text-7xl font-bold text-white mb-10">
                Welcome to the Astomverse Community
              </h1>
              {/* Community Description */}
              <p className="text-base sm:text-lg text-white leading-relaxed mb-6">
                The
                <span className="font-bold text-purple-200">
                  {" "}
                  Astomverse Community{" "}
                </span>
                 aims to empower the next generation of creative
                thinkers and tech innovators. Our mission is to build a talented
                team of students passionate about startups, entrepreneurship,
                and transformative technology. Through hands-on workshops,
                mentorship, and collaborative projects, we provide opportunities
                for students to grow within the startup ecosystem and develop
                the skills needed to lead in the future of technology.
              </p>

              <div className="mt-12">
                <button
                  onClick={handleJoinCommunity}
                  className="lg:ml-3 w-full lg:w-3/12 flex justify-center hover:from-violet-600 hover:to-fuchsia-600  font-bold p-3 px-8 rounded-full text-white bg-gradient-to-r from-violet-500 to-fuchsia-500"
                >
                  Join the Community
                </button>
              </div>
            </div>
            <div className="w-full flex  justify-center align-middle">
              <img
                src="https://test-astomverse-developer-assets.s3.ap-south-1.amazonaws.com/community+assets/6.png"
                alt="log"
                className="w-full lg:h-[380px] outline-1 rounded-3xl outline-purple-300  outline"
              />
            </div>
          </div>

          {/* Community Members' Work Section */}
          <div className="my-28">
            <h2 className="text-2xl sm:text-3xl font-bold text-center text-white my-10">
              Community Member's Work
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-20">
              {memberWorks.map((work) => (
                <div
                  key={work.id}
                  className="mx-auto outline-1 rounded-3xl outline-gray-700 outline"
                >
                  <div className="">
                    <img
                      src={work.image}
                      alt={work.title} // Added alt attribute for accessibility
                      className="lg:h-[240px] w-full outline-1 rounded-3xl outline-gray-700 outline"
                    />
                  </div>
                  <div className="mt-2 p-3">
                    <div className="">
                      <p className="text-white text-1xl lg:text-2xl font-bold">
                        {work.title}
                      </p>
                      <p className="text-gray-400 text-sm mt-1">
                        By: {work.memberName} - {work.branch}
                      </p>
                      <p className="text-gray-300 text-1xl mt-1 ">
                        {work.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="w-12/12 lg:w-10/12 mx-auto py-16  flex flex-col justify-center align-middle gap-4">
            <div className="flex justify-center  lg:py-4 lg:pt-3 align-middle lg:border-y border-zinc-500">
              <span className="text-zinc-100  font-bold text-2xl lg:text-4xl ">
                Community Members
              </span>
            </div>
            <div className="flex justify-center align-middle">
              <img
                src="https://test-astomverse-developer-assets.s3.ap-south-1.amazonaws.com/community+assets/astomverse+community.jpg"
                alt="astomverse community"
                className=" outline-1 rounded-2xl outline-purple-300  outline"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Community;
