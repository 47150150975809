import React from 'react'
import NewBlogForm from '../../components/Forms/NewBlogForm'

const AddNewBlog = () => {
  return (
    <div className='py-20 bg-gray-100'>
      <NewBlogForm />
    </div>
  )
}

export default AddNewBlog
