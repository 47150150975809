function updateTableData(currentList, updatedData) {
  console.log(currentList,updatedData)
  const updatedList = currentList.map((item) => {
    if (item._id === updatedData._id) return updatedData;
    else return item;
  });
  return updatedList;
}

function removeDeleteData(currentList, deletedId) {
  const newList = currentList.filter((item) => item._id !== deletedId);
  return newList;
}

function capitalizeFirstLetter(sentence){
  if (typeof sentence !== 'string' || sentence?.length === 0) {
    return sentence; 
  }
  return sentence?.charAt(0)?.toUpperCase() + sentence?.slice(1);
}

const countKeyValuePair = (array, key, value) => {
  return array?.filter(item => item[key] === value)?.length;
};

const compareClasses = (a, b) => {
  console.log(a)
   const aNum = parseInt(a?.className.split(' ')[1], 10);
   const bNum = parseInt(b?.className.split(' ')[1], 10); 
  return aNum - bNum;
};

const compareSerial = (a, b) => {
  const aParts = a.split('.').map(Number);
  const bParts = b.split('.').map(Number);

  for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
      const aPart = aParts[i] || 0;
      const bPart = bParts[i] || 0;

      if (aPart < bPart) return -1;
      if (aPart > bPart) return 1;
  }

  return 0;
};


export {
  updateTableData,
  removeDeleteData,
  capitalizeFirstLetter,
  countKeyValuePair,
  compareClasses,
  compareSerial
};
