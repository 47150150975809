import React from "react";
import SectionTop from "./SectionTop";
import "./style.css";
import SectionMiddle from "./SectionMiddle";
import { Helmet } from "react-helmet-async";

const Landing = () => {
  return (
    <div className="">
      <Helmet>
        <title>Astomverse - Innovative Edtech Startup</title>
        <meta
          name="description"
          content="Astomverse is an innovative edtech startup, officially recognized by Startup India, nurtured within the prestigious incubation ecosystem of NIT Patna, and supported & funded by Startup Bihar. The company leverages cutting-edge technologies such as Virtual Reality, Augmented Reality, Mixed Reality, Spatial Computing, AI, and Blockchain to revolutionize the educational landscape."/>
        <meta
          name="keywords"
          content="EdTech, Virtual Reality, Augmented Reality, Mixed Reality, AI, Blockchain, Spatial Computing"
        />
        <meta name="author" content="Niranjan Kumar" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://astomverse.com/" />
      </Helmet>
      <SectionTop />
      <SectionMiddle />
    </div>
  );
};

export default Landing;
