import React from "react";
import Detail from "./Detail";
import Suggestion from "./Suggestion";

const BlogDetail = () => {
  return (
    <div className="lg:w-full flex flex-col lg:flex-row py-24 sm:py-24 lg:py-28 bg-gray-100 px-3 gap-3">
      <div className="lg:w-4/5">
        <Detail />
      </div>
      <div className="lg:w-2/5">
        <Suggestion />
      </div>
    </div>
  );
};

export default BlogDetail;
