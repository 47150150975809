export function getDate(timestamp) {
  const time = new Date(timestamp).toLocaleString("en-IN", {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });
  return time;
}
export function addOneDay(date = null) {
  if(date!==undefined&&date!==null){
    date=new Date(date)
    date?.setDate(date?.getDate() + 1);
    return new Date(date).toISOString();
  }
  return date;
}
