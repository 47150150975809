import React from 'react'

const About = () => {
  return (
    <div className='bk'>
       <div className="flex flex-row align-middle justify-between">
        <div className="my-56 mx-56">
          <p className="text-white text-6xl font-bold">Bringing Imagination into Reality</p>
          
          <p className="text-xl  text-gray-200 my-10 ">
            Astomverse is an innovative edtech startup, nurtured within the
            prestigious incubation ecosystem of NIT Patna and funded by start-bihar. This forward-thinking
            company is driven by cutting-edge technologies such as Virtual
            Reality, Augmented Reality, Mixed Reality, Spatial Computing,
            Artificial intelligence, and blockchain.
          </p>
        </div>
      </div>
    </div>
  )
}

export default About
