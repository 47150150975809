import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {API_LOGIN, API_LOGOUT} from "../api/auth.service";
import axiosInstance from "../api/axios.instance";
import parseJwt from "../utils/authUtils";

export const login = createAsyncThunk(
  "auth/login",
  async (credentials, thunkAPI) => {
    try {
      //console.log(credentials)
      const data = await API_LOGIN(credentials);
      return data.data;
    }
    catch (err) {
      return thunkAPI.rejectWithValue(err.response.data)
    }
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  async (_, thunkAPI) => {
    try {
      const data = await API_LOGOUT();
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data)
    }
  }
)

const initialState = { 
  loading: false,
  isLogedIn: false,
  user: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser(state, action) {
      state.isLogedIn = true;
      state.user = action.payload
    },
    resetUser(state) {
      state.isLogedIn = false;
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      const token = action.payload.token;
      localStorage.setItem('token', token);
      axiosInstance.defaults.headers.common["authorization"] = token;
      const user = parseJwt(token);
      state.user = user
      state.isLogedIn = true;
      state.loading = false;
    });
    builder.addCase(login.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loading = false
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.isLogedIn = false;
      state.user = null;
      state.loading = false;
    })
    builder.addCase(logout.pending, (state) => {
      state.loading = true;
    })
    builder.addCase(logout.rejected, (state) => {
      state.loading = false;
    })

  },
});

export const { setUser, resetUser } = authSlice.actions;
export default authSlice.reducer;