import "./style.css";

function Loader({ visible }) {
  return visible ? (
    <div className="overlay1">
      <div className="loader">
        <div className="segment-holder">
          <div className="segment one"></div>
        </div>
        <div className="segment-holder">
          <div className="segment two"></div>
        </div>
        <div className="segment-holder">
          <div className="segment three"></div>
        </div>
        <div className="segment-holder">
          <div className="segment four"></div>
        </div>
      </div>
    </div>
  ) : null;
}

export default Loader;
