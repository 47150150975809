import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const Error = () => {
  const location = useLocation();
  const navigate = useNavigate();


  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/"); // Navigate to home page
    }, 5000); // 5000ms = 5 seconds

    return () => clearTimeout(timer); // Cleanup the timer if the component unmounts
  }, [navigate]);

  return (
    <div className="error-page">
      <header className="header__app">
        <Header />
      </header>

    
        <div className="bk flex w-full  align-middle justify-between pt-8 mx-auto">
          <div className="mx-4 lg:mx-auto flex my-20 py-20  align-middle justify-between flex-col gap-3">
            <h1 className="text-white text-2xl lg:text-4xl font-bold"> Oops!!!   404 - Page Not Found</h1>
           
            <p className="text-slate-400 mt-3 font-bold">You will be redirected to the home page in 5 seconds...</p>
          </div>
        </div>
   

      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default Error;
